

.App {
    font-family: sans-serif;
    text-align: center;
}

input#basic_password {
    background: transparent;
}

.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #2F4858 !important;
    transition: all 0.2s;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #224e76 !important;
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

span.ant-modal-close-x {
    display: none;
}

.ant-input[disabled] {
    color: rgb(0 0 0) !important;
    background-color: #ffffff !important;

}

